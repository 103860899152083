export const debounceFn = (fn: Function, delay = 1000) => {
  let timer: any;
  return function (this: any, ...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => fn.apply(this, args), delay);
  };
};

export function setChatbotParams(orgPartyId:any, jsessionId:any) {
  console.log("In Function",orgPartyId,jsessionId)
const script = document.getElementById('rc-chatbot');
if (script) {
 console.log("found")
 script.setAttribute('organization-party-id', orgPartyId);
 script.setAttribute('jsession-id', jsessionId);
} else {
 console.error('Script tag with id "rc-chatbot" not found.');
}
}